@font-face {
  font-family: optimistic;
  src: url(fonts/Optimistic_Text_W_Rg.woff2);
}

button, input[type=submit] {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 46px;
  margin: 10px 10px;
  border-radius: 50px;
  border-width: 0px;
  background-color: #0064e0;
  font-family: optimistic, notosans-kr, Helvetica Neue, sans-serif;
  color: white;
}

html,
body {
  background-image: url("./img/bg-gradient.png");
  background-repeat: no-repeat;
  background-attachment: fixed;  
  background-size: 100% 100%;
  max-width: 100%;
  height:100%;
  margin: auto;
  padding: 0;
  text-align: center;
  color: #1C2833;
  font-family: optimistic-regular, notosans-kr, Helvetica Neue, sans-serif;
  word-break: keep-all;
}

h2{
  font-size: 22px;
}

#root{
  height:100%;
  width:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content{
  position:relative;
  height: 100%;
  width: 100%;
  padding-top:5px;
}

.main-content{
  position:absolute;
  margin:0;
  width:80%;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -35%);
}